import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../images/all/favicon/favicon.ico"
import ogpImgJP from "../images/all/ogp_jp.png"
import ogpImgEN from "../images/all/ogp_en.png"
import defaultAppIcon from "../images/all/autoradiograph-icon.png"

function SEO({ description, lang, meta, title, ogpImageUrl, keywords, location }) {
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: site.siteMetadata.siteUrl + (!!location ? location.pathname : ''),
    inLanguage: lang,
    mainEntityOfPage: site.siteMetadata.siteUrl,
    description: site.siteMetadata.description,
    name: site.siteMetadata.title,
    author: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    creator: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    publisher: {
      '@type': 'Person',
      name: site.siteMetadata.author,
    },
    datePublished: '2020-03-11T10:30:00+01:00',
    dateModified: site.siteMetadata.buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${site.siteMetadata.siteUrl}${defaultAppIcon}`,
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={(title === "top" ? `` : `%s｜`) + site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title === "top" ? `放射線像／Autoradiograph` : title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title === "top" ? `放射線像／Autoradiograph` : title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogpImageUrl ? `https:${ogpImageUrl}` : (`https://www.autoradiograph.org` + (lang === 'ja' ? ogpImgJP : ogpImgEN))
        },
        {
          property: `og:url`,
          content: url
        }
      ]
        .concat(
          keywords.length > 0 //キーワードが設定されている時のみ表示
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    >
      {<script type="application/ld+json">{JSON.stringify(structuredData)}</script>}
      <link rel="icon" href={favicon} />
      <link
        rel="canonical"
        href={`${site.siteMetadata.siteUrl}${!!location ? location.pathname : '/'}`}
      />
      <link rel="alternate" hreflang="ja-jp" href="https://www.autoradiograph.org" />
      <link rel="alternate" hreflang="en" href="https://www.autoradiograph.org/en" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
