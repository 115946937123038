module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Parisienne","Sawarabi Mincho"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46628900-1","head":true,"respectDNT":true,"exclude":["/404.html","/thanks.html"],"sampleRate":100,"anonymize":false,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"放射線像／Autoradiograph","short_name":"放射線像／Autoradiograph","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/all/autoradiograph-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd318c9091744335471e87703fb9fe4b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","ja"],"defaultLanguage":"ja","redirect":false,"redirectComponent":"/opt/build/repo/src/components/redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
