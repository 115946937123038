import auth0 from "auth0-js"
import { navigate } from "gatsby"
const { GATSBY_FUNCTION_DOMAIN } = process.env

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
    ? new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
        redirectUri: process.env.GATSBY_AUTH0_CALLBACK + "/",
        // redirectUri: process.env.GATSBY_AUTH0_TEST_CALLBACK + "/",
        responseType: "token id_token",
        scope: "openid profile email",
    })
    : {}


const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
    if (!isBrowser) {
        return;
    }

    return localStorage.getItem("isLoggedIn") === "true"
}

export const login = () => {
    if (!isBrowser) {
        return
    }

    auth.authorize()
}

export const signup = () => {
    if (!isBrowser) {
        return
    }
    auth.authorize({ screenHint: 'signup' })
}

const setSession = (cb = () => { }) => (err, authResult) => {
    if (err) {
        navigate("/archive")
        cb()
        return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
        tokens.accessToken = authResult.accessToken
        tokens.idToken = authResult.idToken
        tokens.expiresAt = expiresAt
        user = authResult.idTokenPayload

        const fetchUserDetailAndNavigate = async (user) => {
            try {
                // Check User purchase history
                const userDetail =
                    await fetch(`${GATSBY_FUNCTION_DOMAIN}/fetch-paid-status?user_id=${user.sub}`)
                        .then(data => data.json())
                const savedSettingLang = userDetail.user_metadata.savedDefaultLang

                if (!isAuthenticated()) {
                    localStorage.setItem("isLoggedIn", true)
                    savedSettingLang === 'en' ? navigate('/en/archive') : navigate('/ja/archive')
                }
            } catch (err) {
                throw new Error(err)
            }

        }
        fetchUserDetailAndNavigate(user)

        cb()
    }
}

export const handleAuthentication = () => {
    if (!isBrowser) {
        return;
    }

    auth.parseHash(setSession())
}

export const getProfile = () => {
    return user
}

export const silentAuth = callback => {
    if (!isAuthenticated()) return callback()
    auth.checkSession({}, setSession(callback))
}

export const logout = () => {
    localStorage.setItem("isLoggedIn", false)
    auth.logout()
    navigate("/archive")
}